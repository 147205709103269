import * as types from "../types/request";
import axios from "axios";
import { getToken } from "../../adalConfig";
import { REQUEST_STEP_AT } from "../../enums";

export const postAccessRequest = (
    requestData,
    setLoadingSubmitRequest,
    setRequestStep
    //,setActiveStep,
    //setRequestUserData,
    //setManagerData,
    //setUserEmailSelected
) => async dispatch => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` }
    };

    const body = {
        IsInternal: requestData.isInternal,
        UserId: requestData.UserId,
        Email: requestData.Email,
        RegionId: requestData.RegionId,
        MarketId: requestData.MarketId,
        AgencyId: requestData.AgencyId,
        PracticeId: requestData.PracticeId,
        PlatformId: requestData.PlatformId,
        ClientId: requestData.ClientId,
        ClientCodeId: requestData.ClientCodeId,
        ProductCode: requestData.ProductCode,
        PlatformOwnerShipId: requestData.PlatformOwnerShipId,
        SharePointId: requestData.SharePointId,
        Comments: requestData.Comments,
        ApprovedRejectedBy: requestData.ApprovedRejectedBy,
        IsUrgent: requestData.IsUrgent,
        RequestAccountAccess: requestData.RequestAccountAccess,
        ManagerEmail: requestData.ManagerEmail,
        ManagerName: requestData.ManagerName
    };

    await axios.post("api/Request/Create", body, config).then(function (response) {
        if (response.data.succeeded) {
            alert(response.data.message);
            //setActiveStep(0);
            //setRequestUserData(null);
            //setManagerData({});
            //setUserEmailSelected(null);
            //setRequestStep(REQUEST_STEP_AT.REQUEST_SUCCESS);
            setLoadingSubmitRequest(false);
        } else {
            setRequestStep(REQUEST_STEP_AT.PLATFORM_SELECTION);
            setLoadingSubmitRequest(false);
            if (response.data.data) {
                var reqId = parseInt(response.data.message.match(/\d+/g));
                var result = window.confirm(response.data.message);
                if (result) {
                    window.open('ChangeUserRecords?Id='+reqId, '_blank');
                }
            }
            else
                alert(response.data.message);
        }
    });
};

export const postSpecialAccessRequest = (
    requestData,
    setLoading,
    closeAndClean
) => async dispatch => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` }
    };

    const body = requestData;

    axios.post("api/Request/Create", body, config).then(function (response) {
        if (response.data.succeeded) {
            setLoading(false);
            closeAndClean();
            alert(response.data.message);
            alert('You have submitted your request to the agency Business Manager/Center.   Proceed with requesting access to any required assets (accounts, asset groups etc.)');
        } else {
            alert(response.data.message);
            setLoading(false);
        }
    });
};

export const registerAndGetUserId = setLoading => async dispatch => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` }
    };
    const body = {};

    axios
        .post("/api/User/ValidateNewUser", body, config)
        .then(function (response) {
            setLoading(false);
            dispatch({
                type: types.FETCH_USER_ID,
                payload: response.data.data
            });
        });
};

export const setRequestFor = isRequestForMe => async dispatch => {
    dispatch({
        type: types.SET_REQUEST_FOR,
        payload: isRequestForMe
    });
};

export const setRequestIsForInternal = isRequestForInternal => async dispatch => {
    dispatch({
        type: types.SET_REQUEST_INTERNAL,
        payload: isRequestForInternal
    });
};

export const setManagerData = managerData => async dispatch => {
    dispatch({
        type: types.SET_MANAGER,
        payload: managerData
    });
};

export const setRequestUserData = userData => async dispatch => {
    dispatch({
        type: types.SET_REQUEST_USER_DATA,
        payload: userData
    });
};

export const setPrimaryApprover = userData => async dispatch => {
    dispatch({
        type: types.SET_PRIMARY_APPROVER,
        payload: userData
    });
};

export const setSecondaryApprover = userData => async dispatch => {
    dispatch({
        type: types.SET_SECONDARY_APPROVER,
        payload: userData
    });
};

export const setPrimaryProvisioner = userData => async dispatch => {
    dispatch({
        type: types.SET_PRIMARY_PROVISIONER,
        payload: userData
    });
};

export const setSecondaryProvisioner = userData => async dispatch => {
    dispatch({
        type: types.SET_SECONDARY_PROVISIONER,
        payload: userData
    });
};

export const setLoggedUserNewdata = userData => async dispatch => {
    dispatch({
        type: types.SET_USER_LOGGED_DATA,
        payload: userData
    });
};

export const setUserEmailSelected = userEmail => async dispatch => {
    dispatch({
        type: types.SET_USER_EMAIL_SELECTED,
        payload: userEmail
    });
};

export const setComments = comment => async dispatch => {
    dispatch({
        type: types.SET_COMMENTS,
        payload: comment
    });
};

export const cleanRequestUserdata = () => async dispatch => {
    dispatch({
        type: types.CLEAN_REQUEST_USER_DATA,
        payload: null
    });
};

export const setSelectedUserForEdit = userData => async dispatch => {
    dispatch({
        type: types.SELECTED_USER_FOR_EDIT,
        payload: userData
    });
};

export const updateUserData = userDataObject => async dispatch => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` }
    };

    const body = userDataObject;

    axios.put("api/User", body, config).then(function (response) {
        dispatch({
            type: types.UPDATE_USER
        });
        userDataObject.userId = userDataObject.userId == 0 ? response.data.data.userId : userDataObject.userId;
        userDataObject.roleId = userDataObject.roleId == 0 ? response.data.data.roleId : userDataObject.roleId;

    });
};

export const updateUserDataForAdmin = (userDataObject, unchangedUserDataObject, fetchAllUsersList, setShowUserModal, setNameState, setButtonDisabled, setAllSecondaryEmails, setOptionsForUserId, setOptionsForSecondaryEmail, setDiactivateButtonDisabled) => async dispatch => {

    if (userDataObject.isInternal && unchangedUserDataObject.email != userDataObject.email) {
        const internalConfig = {
            headers: { Authorization: `Bearer ${getToken()}` },
            params: {
                filterType: "mail",
                value: userDataObject.email
            }
        };

        axios.get("api/User/SearchInternal", internalConfig).then(function (response) {
            if (response.data.data.length === 1) {
                const updateConfig = {
                    headers: { Authorization: `Bearer ${getToken()}` }
                };

                const updateBody = userDataObject;

                axios.put("/api/User", updateBody, updateConfig).then(function (response) {
                    dispatch({
                        type: types.UPDATE_USER
                    });
                    if (response.data.succeeded) {
                        alert("Successfully updated user data");
                        setShowUserModal(false);
                        setNameState({});
                        setButtonDisabled(true);
                        setAllSecondaryEmails([]);
                        setOptionsForUserId([]);
                        setOptionsForSecondaryEmail([]);
                        fetchAllUsersList();
                        setDiactivateButtonDisabled(true);
                    }
                    else {
                        alert("Error occurred. Error message is " + response.data.message);
                    }
                });
            } else {
                alert("Cannot find Primary Email in Active Directory");
            }
        });
    }
    else {
        const updateConfig = {
            headers: { Authorization: `Bearer ${getToken()}` }
        };

        const updateBody = userDataObject;

        axios.put("/api/User", updateBody, updateConfig).then(function (response) {
            dispatch({
                type: types.UPDATE_USER
            });
            if (response.data.succeeded) {
                alert("Successfully updated user data");
                setShowUserModal(false);
                setNameState({});
                setButtonDisabled(true);
                setAllSecondaryEmails([]);
                setOptionsForUserId([]);
                setOptionsForSecondaryEmail([]);
                fetchAllUsersList();
                setDiactivateButtonDisabled(true);
            }
            else {
                alert("Error occurred. Error message is " + response.data.message);
            }
        });
    }
};

export const searchInternalForAdmin = email => async dispatch => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: {
            filterType: "mail",
            value: email
        }
    };

    axios.get("api/User/SearchInternal", config).then(function (response) {
        if (response.data.data.length === 1) {
            dispatch({
                type: types.SET_REQUEST_USER_DATA,
                payload: response.data.data[0]
            });
        } else {
            alert(response.data.message);
        }
    });
};

export const deactivateUserAdditionalEmail = (additionalEmailObject, optionsForSecondaryEmail, setOptionsForSecondaryEmail, setSelectedSeconadaryEmail) => async dispatch => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` }
    };

    const body = additionalEmailObject;

    axios.put("api/User/DeactivateAdditionalEmail", body, config).then(function (response) {
        if (response.data.succeeded) {
            alert("Successfully Deactivated selected Email");
            let remainingEmails = optionsForSecondaryEmail.filter(function (obj) {
                return obj.value != body.email;
            });
            optionsForSecondaryEmail = remainingEmails
            setOptionsForSecondaryEmail(remainingEmails);
            setSelectedSeconadaryEmail([]);
        } else {
            alert(response.data.message);
        }
    });
};


export const saveNewExternalUser = (
    emailSelected,
    newUserData,
    submitRequest,
    index
) => async dispatch => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` }
    };

    newUserData.email = emailSelected;
    newUserData.isInternal = false;

    const body = newUserData;

    var userId = null;

    axios
        .post("api/User/Save", body, config)
        .then(function (response) {
            userId = response.data.data.userId;
            dispatch({
                type: types.CREATE_NEW_EXTERNAL_USER,
                payload: response.data.data
            });
        })
        .then(() => {
            submitRequest(index, userId);
        });
};

export const getMyData = email => async dispatch => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: {
            filterType: "mail",
            value: email,
            onAzure: "false"
        }
    };

    axios.get("api/User/SearchInternal", config).then(function (response) {
        if (response.data.succeeded) {
            if (response.data.data.length === 1) {
                dispatch({
                    type: types.GET_MY_DATA,
                    payload: response.data.data[0]
                });
            } else {
                alert(response.data.message);
            }
        } 
    });
};

export const getUserData = email => async dispatch => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: {
            filterType: "mail",
            value: email,
            onAzure: "false"
        }
    };

    axios.get("api/User/SearchInternal", config).then(function (response) {
        if (response.data.data.length === 1) {
            dispatch({
                type: types.SET_REQUEST_USER_DATA,
                payload: response.data.data[0]
            });
        } else {
            alert(response.data.message);
        }
    });
};

export const getUserDataExternal = email => async dispatch => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: {
            filtervalue: email
        }
    };

    axios.get("api/User/SearchExternal", config).then(function (response) {
        if (response.data.data.length === 1) {
            dispatch({
                type: types.SET_REQUEST_USER_DATA,
                payload: response.data.data[0]
            });
        } else {
            alert(response.data.message);
        }
    });
};

export const fetchUserRecordsOfADInactiveUsers = (
    setData,
    setLoading
) => async dispatch => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` }
    };

    axios
        .get("api/Request/UserRecordsOfADInactiveUsers", config)
        .then(function (response) {
            if (response.data.succeeded) {
                setLoading(false);
                setData(response.data.data);
            } else {
                alert(response.data.message);
            }
        });
};

export const setRequestStep = data => async dispatch => {
    dispatch({
        type: types.SET_REQUEST_STEP,
        payload: data
    });
};

export const fetchLogsById = (setData, setLoading, logId) => async dispatch => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` }
    };

    axios.get(`/api/Request/Logs/${logId}`, config).then(function (response) {
        if (response.data.succeeded) {
            setData(response.data.data);
            setLoading(false);
        } else {
            alert(response.data.message);
        }
    });
};

export const resetReduxStateRequest = userEmail => async dispatch => {
    dispatch({
        type: types.RESET_STATE
    });
};

export const validateSpecialAccessRequest = (
    requestData,
    succedFunction,
    notSuccedFunction
) => async dispatch => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: requestData
    };

    axios
        .get("api/Request/ValidatePlatformRequest", config)
        .then(function (response) {
            if (response.data.succeeded) {
                succedFunction(response);
            } else {
                notSuccedFunction();
                alert(response.data.message);
            }
        });
    };
export const getBusinessAccessAccount = (
    requestData,
    succedFunction,
    notSuccedFunction
) => async dispatch => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` }
    };

    const body = {
        UserId: requestData.userId,
        Email: requestData.email,
        RegionId: requestData.regionId,
        MarketId: requestData.marketId,
        AgencyId: requestData.agencyId,
        PracticeId: requestData.practiceId,
        PlatformId: requestData.platformId,
        ClientId: requestData.clientId,
        RequestAccountAccess: requestData.selectedaccounts,
    };
    axios
        .post("api/Request/ValidateBusinessManagerAccount", body,config)
        .then(function (response) {
            if (response.data.succeeded) {
                succedFunction(response);
            } else {
                notSuccedFunction();
                alert(response.data.message);

            }
        });
};

export const deactiveLeaversRequest = (
    isActive,
    rowOrList,
    fetchRequestAgain,
    setLoading,
    setShowModal,
    setSelectedRows,
    bodyProps
) => async (dispatch) => {
    const config = {
        headers: { Authorization: `Bearer ${getToken()}` },
    };
   
    var body = {};
    var setData = [];

    body = {
        ...bodyProps,
        isActive: isActive,
        requests: rowOrList,
    };

    axios
        .post(`api/Request/DeactivateLeaversRecord`, body, config)
        .then(function (response) {
            if (response.data.succeeded) {
                setSelectedRows([]);
                setLoading(false);
                setShowModal(false);
                alert(response.data.message);
                window.location.reload(false);
            } else {
                alert(response.data.message);
            }
        });
};
