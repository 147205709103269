import React, { useState, useEffect } from "react";
import MainContainer from "../UI/MainContainer/MainContainer";
import { Modal, Col, Row, Spinner } from "react-bootstrap";
import { div, CardContent, Badge, Toolbar, AppBar } from "@material-ui/core";
import CustomCall from "../../actions/CustomCall/CustomCall";



const AgencyClients = () => {
    const onClick = (evt, link) => {
        window.open(link, "_blank");
    };
    const buttonStyle = {
        backgroundColor: "rgb(255 255 255)",
    };
    const platformButtonStyle = {
        backgroundColor: "rgb(255 255 255)",
    };
    const col1 = {
        alignSelf: 'center',
        height: 'auto',
        width: '25%'
    };
    const col2 = {
        height: 'auto',
        width: '70%',
        paddingRight: '3rem'
    };
    const textStyle = {
        fontWeight: 'bold',
        color: "rgb(13, 112, 188)",
        fontFamily: "sans-serif"
    };
    const subtextStyle = {
        fontWeight: 'bold',
        color: "rgb(39 43 55)",
        fontFamily: "sans-serif"
    };

    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState("");
    const [, setShowAlert] = useState(false);
    const [customAlert, setCustomAlert] = useState(null);
    const [fetchedData, setFetchedData] = useState([]);
    const [loading, setLoading] = useState(true);

    const allClients = (platform, agency) => {
        setLoading(true);
        CustomCall(
            "get",
            `api/Catalogs/GetAgencyClients/${platform}/${agency}`,
            {},
            function (response) {
                setFetchedData(response.data.data);
                setLoading(false);
            },
            setCustomAlert,
            setShowAlert
        );

    };
    const onHideModal = () => {
        setShowModal(false);
    }

    return (
        <React.Fragment>

            <MainContainer>
                <Row>
                    <Col xs={12} style={{ paddingLeft: '5rem' }} className="pt-1">
                        <h4 style={textStyle}>Requests for access are managed through the UAP for the following agency clients by platform</h4>
                        <h6 style={subtextStyle}>Click the logos below for more detail.</h6>
                    </Col>
                </Row>
                <hr className="mt-4 mb-4" style={{ borderTop: '5px solid rgba(0,0,0,.1)' }}></hr>
                <Row>
                    <Col xs={12} sm={4} md={3} lg={3} xl={3} style={{ alignSelf: "center" }} style={col1}>
                        <Row style={{ justifyContent: "center" }}>
                            <Col xs={12} sm={12} md={{ span: 10, offset: 0 }} style={{ textAlign: "center" }} >
                                <div style={platformButtonStyle} >
                                    <CardContent>
                                        <img
                                            alt="GoogleAds"
                                            src="/GoogleAds.png"
                                            width="220"
                                            height="100"
                                            style={{maxWidth:"100%", height:"auto"} }
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={9} lg={9} xl={9} style={col2}>
                        <Row className="text-center pt-1">
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">

                                <div
                                    style={buttonStyle}
                                    onClick={() => {
                                        setTitle("Catalyst Google Ads");
                                        setShowModal(true);
                                        allClients('Google Ads', 'Catalyst');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="Catalyst"
                                            src="/Catalyst.png"
                                            width="180"
                                            height="65"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("EightBar Google Ads");
                                        setShowModal(true);
                                        allClients('Google Ads', 'EightBar');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="EightBar"
                                            src="/EightBar.png"
                                            width="90"
                                            height="90"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">

                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("EssenceMediacom Google Ads");
                                        setShowModal(true);
                                        allClients('Google Ads', 'EssenceMediacom');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="EssenceMediacom"
                                            src="/EssenceMediaCom_.png"
                                            width="160"
                                            height="15"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">

                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("Mindshare Google Ads");
                                        setShowModal(true);
                                        allClients('Google Ads', 'Mindshare');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="Mindshare"
                                            src="/Mindshare.png"
                                            width="185"
                                            height="25"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">

                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("mSix Google Ads");
                                        setShowModal(true);
                                        allClients('Google Ads', 'mSix');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="mSix"
                                            src="/Msix.png"
                                            width="185"
                                            height="45"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">

                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("Neo Google Ads");
                                        setShowModal(true);
                                        allClients('Google Ads', 'Neo');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="Neo"
                                            src="/Neo.png"
                                            width="145"
                                            height="45"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">

                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("Openmind Google Ads");
                                        setShowModal(true);
                                        allClients('Google Ads', 'Openmind');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="Openmind"
                                            src="/Openmind.png"
                                            width="185"
                                            height="50"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("Wavemaker Google Ads");
                                        setShowModal(true);
                                        allClients('Google Ads', 'Wavemaker');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="Wavemaker"
                                            src="/Wavemaker.png"
                                            width="185"
                                            height="25"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr className="mt-4 mb-4" style={{ borderTop: '5px solid rgba(0,0,0,.1)' }}></hr>
                <Row>
                    <Col xs={12} sm={4} md={3} lg={3} xl={3} style={{ alignSelf: "center" }} style={col1}>
                        <Row style={{ justifyContent: "center" }}>
                            <Col xs={12} sm={12} md={{ span: 10, offset: 0 }} style={{ textAlign: "center" }} >
                                <div style={platformButtonStyle} >
                                    <CardContent>
                                        <img
                                            alt="Meta"
                                            src="/Meta.png"
                                            width="220"
                                            height="110"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={9} style={col2}>
                        <Row className="text-center pt-1">
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("EightBar Meta");
                                        setShowModal(true);
                                        allClients('Meta', 'EightBar');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="EightBar"
                                            src="/EightBar.png"
                                            width="90"
                                            height="90"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("EssenceMediaCom Meta MediaCom Nam_TCCC");
                                        setShowModal(true);
                                        allClients('Meta MediaCom Nam_TCCC', 'EssenceMediacom');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="EssenceMediacom"
                                            src="/EssenceMediaCom.png"
                                            width="160"
                                            height="15"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                        <br></br>
                                        <img style={{ padding: '10px' }}
                                            alt="cocacola"
                                            src="/cocacola.png"
                                            width="90"
                                            height="45"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("EssenceMediaCom Meta");
                                        setShowModal(true);
                                        allClients('Meta', 'EssenceMediacom');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="EssenceMediacom"
                                            src="/EssenceMediaCom.png"
                                            width="160"
                                            height="15"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        /><br></br>
                                        <img
                                            alt="Mediacom"
                                            src="/Mediacom.png"
                                            width="80"
                                            height="15"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("Mindshare Meta");
                                        setShowModal(true);
                                        allClients('Meta', 'Mindshare');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="Mindshare"
                                            src="/Mindshare.png"
                                            width="185"
                                            height="25"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("mSix Meta");
                                        setShowModal(true);
                                        allClients('Meta', 'mSix');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="mSix"
                                            src="/Msix.png"
                                            width="185"
                                            height="45"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("Neo Meta");
                                        setShowModal(true);
                                        allClients('Meta', 'Neo');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="Neo"
                                            src="/Neo.png"
                                            width="145"
                                            height="45"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>

                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("Openmind Meta");
                                        setShowModal(true);
                                        allClients('Meta', 'Openmind');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="Openmind"
                                            src="/Openmind.png"
                                            width="185"
                                            height="50"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("Wavemaker Meta");
                                        setShowModal(true);
                                        allClients('Meta', 'Wavemaker');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="Wavemaker"
                                            src="/Wavemaker.png"
                                            width="185"
                                            height="25"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>

                        </Row>
                    </Col>
                </Row>
                <hr className="mt-4 mb-4" style={{ borderTop: '5px solid rgba(0,0,0,.1)' }}></hr>
                <Row>
                    <Col xs={12} sm={4} md={3} lg={3} xl={3} style={{ alignSelf: "center" }} style={col1}>
                        <Row style={{ justifyContent: "center" }}>
                            <Col xs={12} sm={12} md={{ span: 10, offset: 0 }} style={{ textAlign: "center" }} >
                                <div style={platformButtonStyle} >
                                    <CardContent>
                                        <img
                                            alt="TikTok"
                                            src="/TikTok.png"
                                            width="220"
                                            height="85"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={9} style={col2}>
                        <Row className="text-center pt-1">
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("EssenceMediacom TikTok MediaCom_NAOU_US_TCCC");
                                        setShowModal(true);
                                        allClients('TikTok MediaCom_NAOU_US_TCCC', 'EssenceMediacom');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="EssenceMediacom"
                                            src="/EssenceMediaCom.png"
                                            width="160"
                                            height="15"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                        <br></br>
                                        <img style={{ padding: '10px' }}
                                            alt="cocacola"
                                            src="/cocacola.png"
                                            width="90"
                                            height="45"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("EssenceMediacom TikTok Essence");
                                        setShowModal(true);
                                        allClients('TikTok Essence', 'EssenceMediacom');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="EssenceMediacom"
                                            src="/EssenceMediaCom.png"
                                            width="160"
                                            height="15"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                        <br></br>
                                        <img
                                            alt="Essence"
                                            src="/Essence.png"
                                            width="90"
                                            height="12"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("EssenceMediacom TikTok Mediacom Mars US");
                                        setShowModal(true);
                                        allClients('TikTok Mediacom Mars US', 'EssenceMediacom');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="EssenceMediacom"
                                            src="/EssenceMediaCom.png"
                                            width="160"
                                            height="15"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                        <br></br>
                                        <img
                                            alt="Mars"
                                            src="/mars.png"
                                            width="80"
                                            height="15"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>

                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("EssenceMediacom TikTok");
                                        setShowModal(true);
                                        allClients('TikTok', 'EssenceMediacom');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="EssenceMediacom"
                                            src="/EssenceMediaCom.png"
                                            width="160"
                                            height="15"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                        <br></br>
                                        <img
                                            alt="Mediacom"
                                            src="/Mediacom.png"
                                            width="80"
                                            height="15"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("Mindshare TikTok");
                                        setShowModal(true);
                                        allClients('TikTok', 'Mindshare');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="Mindshare"
                                            src="/Mindshare.png"
                                            width="185"
                                            height="25"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>

                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("mSix TikTok");
                                        setShowModal(true);
                                        allClients('TikTok', 'mSix');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="mSix"
                                            src="/Msix.png"
                                            width="185"
                                            height="45"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("Neo TikTok");
                                        setShowModal(true);
                                        allClients('TikTok', 'Neo');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="Neo"
                                            src="/Neo.png"
                                            width="145"
                                            height="45"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>

                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("Openmind TikTok");
                                        setShowModal(true);
                                        allClients('TikTok', 'Openmind');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="Openmind"
                                            src="/Openmind.png"
                                            width="185"
                                            height="50"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                           
                           
                            <Col xs={12} sm={4} md={{ span: 4, offset: 0 }} lg={4} xl={3} style={{ alignSelf: "center" }} className="pt-1">
                                <div style={buttonStyle}
                                    onClick={() => {
                                        setTitle("Wavemaker TikTok");
                                        setShowModal(true);
                                        allClients('TikTok', 'Wavemaker');
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="Wavemaker"
                                            src="/Wavemaker.png"
                                            width="185"
                                            height="25"
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    </CardContent>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr className="mt-4 mb-4" style={{ borderTop: '5px solid rgba(0,0,0,.1)' }}></hr>
            </MainContainer>
            <React.Fragment>
                <Modal
                    show={showModal}
                    onHide={() => {
                        onHideModal();
                        setFetchedData([]);
                    }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{title} clients
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        {loading ? (
                            <Col xs={12} style={{ textAlign: "center" }} className="pt-1">
                                <Spinner animation="border" size="lg" />
                            </Col>
                        ) : fetchedData.length > 0 ? (
                            <ol>
                                {fetchedData.map((item) => (
                                    <li style={{ listStyleType: 'none' }} key={item.id}>{item.client}</li>
                                ))}
                            </ol>
                        ) : (
                            <p>No data found</p>
                        )}
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        </React.Fragment>
    );
};

export default AgencyClients;


